@import '../themes/mixins.scss';

.accordion {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0.2px 1px 2px 2px var(--boxShadow);
  color: var(--color-text);
  border: 0.5px solid var(--boxShadow);
  
}

.accordion-item {
  border-bottom: 1px solid var(--color-grey-dark-1);
}

#accordion-logo {
  /* width: 40px; */
  height: 50px;

  @include respond(tabLand) {
    display: none;
  }

  // @include respond(tabPort) {
  //   width: 250px;
  //   height: 250px;
  //   justify-content: space-around;
  //   // max-height: 80vh;
  // }

  // @include respond(phone) {
  //   width: 150px;
  //   height: 150px;
  //   justify-content: space-around;
  // }

  // accordion-logo
}

#accordion-location-icon {
  /* width: 40px; */
  height: 20px;
}
#accordion-body-top-icon {
  /* width: 40px; */
  height: 15px;
}

.accordion-body-top {
  display: flex;
  align-items: center;
  gap: 30px;
}
.accordion-body-top-content {
  display: flex;
  align-items: center;
}

.accordion-body-top-content p {
  font-size: 14px;
  padding-left: 10px;
}

.accordion-body-top-content a {
  font-size: 16px;
  cursor: pointer;
  color: var(--color-text);
  font-weight: bold;
  padding-left: 10px;
  text-decoration-line: underline;
  -moz-text-decoration-line: underline;
}

.chip-list {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.accordion-chip {
  display: flex;
  padding: 7px 20px;
  background-color: var(--color-mode-icon);
  align-items: center;
  text-align: center;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.accordion-chip p {
  font-size: 14px;
  color: var(--color-bg);
  text-align: center;
  margin: 0;
  font-weight: bold;
}

.accordion-header {
  /* background-color: var(--color-bg); */
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.accordion-header.active {
  background-color: var(--color-bg-2);
  color: var(--color-text);
}

.accordion-header h3,
.accordion-header h4 {
  margin: 0;
}

.accordion-header h3 {
  font-size: 16px;
}
.accordion-header h4 {
  font-size: 16px;
}

.accordion-body {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: var(--color-bg);
  padding: 0 15px;
}

.accordion-body.show {
  max-height: 500px; /* Adjust as needed */
  padding: 15px;
}

.accordion-body ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.accordion-body li {
  margin-bottom: 10px;
  line-height: 1.5;
  text-align: left;
}

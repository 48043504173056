@import './themes/mixins.scss';

body {
  font-family: arial, helvetica;
}

h1,
h2,
h3 {
  font-family: Comfortaa-Bold;
}


p {
  font-family: Comfortaa-Regular;
}

/* h3 {
  font-size: 5em;
  text-align: center;
  color: #fff;
  font-weight: 700;
} */


#section1 {
  color: #fff;
}

.fp-section {
  text-align: center;
}

.fullpage {
  width: 85%;
  margin: auto;

  @include respond(tabPort) {
    width: 95%;
    height: 100vh
  }
}


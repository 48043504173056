@import '../../themes/mixins.scss';
@import 'animate.css';

.about {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // justify-content: center;
  align-items: center;
  // margin: auto 0;

  @include respond(tabPort) {
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
  }

  @include respond(phone) {
    justify-content: space-around;
  }

  // align-items: center;



  &__section-left {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    // margin-right: 10rem;
    // justify-content: space-between;

    @include respond(tabPort) {
      width: 95%;
      margin: auto;
      margin-top: 2.5rem;
    }

    @include respond(phone) {
     margin-top: 2.5rem;
    }

    &--intro {
      flex: 7;
      align-items: flex-start;
      // margin-left: 5rem;

      &__top {
        display: flex;
        flex-direction: row;
        align-items: center;

        div {
          width: 1.6rem;
          height: 2px;
          background-color: var(--color-secondary);
          margin-right: 1rem;
        }

        p {
          color: var(--color-secondary);
          font-size: 1rem;
          font-family: Comfortaa-Bold;

          @include respond(phone) {
            font-size: 0.7rem;
          }
        }
      }

      &__bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1rem;
      }

      &__name {
        font-size: 3rem;
        color: var(--color-text);
        font-family: Comfortaa-Bold;
        text-align: left;
        margin: 2rem 0 1rem 0;

        @include respond(tabPort) {
          font-size: 2rem;
        }

        @include respond(phone) {
          font-size: 1.6rem;
        }

        span {
          color: var(--color-secondary)
        }
      }

      .brief {
        font-size: 1rem;
        line-height: 2rem;
        color: var(--color-text);
        font-family: Comfortaa-Light;
        text-align: left;

        @include respond(tabPort) {
          font-size: 0.9rem;
        }

        @include respond(phone) {
          font-size: 0.8rem;
        }

        span {
          color: var(--color-secondary);
          font-weight: bold;
        }
      }
    }
  }

  &__section-right {
    margin-top: 5rem;
    // width: 35%;
    position: relative;

    @include respond(tabPort) {
      margin: auto;
      // padding-bottom: 10rem;
      // width: 20%;
    }

    // @include respond(phone) {
    //   display: none;
    // }

    &__item {
      position: relative;
      justify-content: center;
      align-items: center;
      // background-color: green;

      img {
        z-index: 10;
        height: 30rem;

        @include respond(tabPort) {
          height: 25rem;
        }

        @include respond(phone) {
          height: 16rem;
        }
      }

      .hexagon {
        position: absolute;
        top: 30%;
        left: 16.5%;
        z-index: -1;
        transform: rotate(270deg);

        @include respond(tabPort) {
          top: 30%;
          left: 13%;
        }

        @include respond(phone) {
          top: 25%;
          left: 9%;
        }

        &__item {
          width: 15rem;
          height: 15rem;
          background: var(--color-secondary);
          position: relative;
          border-radius: 3rem;
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          animation: scaleIn 3s infinite cubic-bezier(.36, .11, .89, .32);


          @include respond(tabPort) {
            width: 14rem;
            height: 14rem;
          }

          @include respond(phone) {
            width: 10rem;
            height: 10rem;
          }
        }
      }


    }
  }

  &__c-container {
    width: 600px;
    height: 400px;
    // display: none;
    // align-items: center;
    justify-content: center;
    // overflow: hidden;
    position: relative;

    &__item {
      z-index: 100;
      padding: 5px;

      img {
        width: 150px;
      }
    }

    @include respond(phone) {
      display: flex;
    }

    &__circle {
      border-radius: 50%;
      background-color: var(--color-secondary);
      width: 150px;
      height: 150px;
      position: absolute;
      opacity: 0;
      animation: scaleIn 3s infinite cubic-bezier(.36, .11, .89, .32);
      -webkit-animation: scaleIn 3s infinite cubic-bezier(.36, .11, .89, .32);
    }




    @keyframes scaleIn {
      from {
        transform: scale(.5, .5);
        opacity: .5;
      }

      to {
        transform: scale(2.5, 2.5);
        opacity: 0;
      }
    }
  }
}

.roundCircle {
  position: absolute;
  height: 5rem;
  opacity: 0.2;
}

.mouseScroll {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  margin: auto; // right: 50%;

  @include respond(tabPort) {
    display: none;
  }
}

.fa-mouse-alt {
  //height: 50;
  //width: 40;
  font-size: 1.8rem;
  color: var(--color-text);
  animation: bounce;
  animation-duration: 3s;
  animation-iteration-count: 3;
}

.btn-left {
  min-width: 10rem;

  @include respond(tabPort) {
    font-size: 0.9rem;
  }

  @include respond(phone) {
    font-size: 0.8rem;
    min-width: 6rem,
  }
}

.btn-right {
  min-width: 10rem;

  @include respond(tabPort) {
    font-size: 0.9rem;
  }

  @include respond(phone) {
    font-size: 0.8rem;
    min-width: 7.5rem,
  }
}

.btn {
  display: inline-block;
  border: 1px solid #CCC;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  border: none !important;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: flex;
  font-family: Comfortaa-Bold;
  // margin-left: auto;
  margin-right: 1rem;
  justify-content: center;
  padding: 0.7rem 0.5rem;
  // background-color: var(--color-text);
  color: var(--color-bg);

  border-radius: 0.5rem;

  @include respond(tabLand) {
    font-size: 1rem;
  }

  @include respond(tabPort) {
    font-size: 0.9rem;
  }

  @include respond(phone) {
    font-size: 0.7rem;
    padding: 0.5rem 0.5rem;
  }

  a {
    color: var(--color-bg);
    font-size: 1rem;

    @include respond(tabLand) {
      font-size: 0.9rem;
    }

    @include respond(phone) {
      font-size: 0.8rem;
    }
  }

  &:hover {
    background-color: var(--color-secondary);
    color: white;
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFTSURBVHgBrZS9UsJAFIWPjC1OrCnc0k4stM4M9vAEArUFWFqJFaVxxNrEF4hvEGobtNLONNZEeID1bFbCXxICyZk5k+xN8u3duzcLJMugBQqSoMe0pJ9RgCzzDNJzQqDEFpmWEuKGqJBSwdYqoWBlBQroTSoM6NHftEk3cgPdAVCvhRl6RhkuryO6Qzd3AlaPgcMyU6uR5OoQN8wi3OZ9Nw5Yhe63ZhqYgHnWj3oC6mQVqJbi8mGLs9rQtTrCBhkH8fH9f6C4vdIv9QYQzqtuZDXuXOrM6hfA72Q5libPPIeUn9rcBMlaReMktxrrv+ashu3hGwJHFzysjdqIXTQD+vRDtw8EE+TSYttYwRT+3RNyaREY0G3rBeDyM2k8TQcqDWnnuo+N8n+Aj6/om0h7Me+qNhqxJ8X9TXx7KJgqDa/vHJ4igwRtY35qr1rFe4g5gf4ApeZ0dUcd+qUAAAAASUVORK5CYII="),
      pointer;
  }

  &:active {
    background-color: var(--color-secondary);
  }

  &:focus {
    border: none !important;
    outline: none;

  }
}
@import '../../themes/mixins.scss';

.skills-nav {
  display: none;

  @include respond(tabPort) {
    position: relative;
    height: 3rem;
    width: 100vw;
    display: flex;
    left: calc(-50vw + 50%);
    flex-direction: row;
    justify-content: space-around;
    margin-top: 3rem;
    box-shadow: 0 1px 2px 0 var(--boxShadow);
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    // background-color: red;
  }

  p {
    font-size: 1rem;
    font-family: Comfortaa-Bold;
    padding: 1rem 0;
    color: var(--color-text);
    // border-bottom: 1px solid var(--color-secondary);

    @include respond(phone) {
      font-size: 0.85rem;
    }
  }
}

.bottom-more {
  display: none;

  @include respond(tabPort) {
    position: absolute;
    bottom: 0;
    height: 3rem;
    width: 100vw;
    display: flex;
    left: calc(-50vw + 50%);
    flex-direction: row;
    justify-content: space-around;
    margin-top: 3rem;
    box-shadow: 0px 0px 2px 0.8px var(--boxShadow);
    border: none;
    background-color: var(--color-bg);
  }

  p {
    font-size: 0.91rem;
    font-family: Comfortaa-Bold;
    color: var(--color-secondary);
  }
}

.skills {
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // margin: auto;
  height: 100vh;

  @include respond(tabPort) {
    // justify-content: space-around;
    height: calc(100vh - 10.5rem);
  }


  &__header {
    // display: flex;
    margin-top: 4.5rem;
    margin-bottom: 1rem;

    @include respond(tabPort) {
      justify-content: center;
      display: flex;
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
    }

    h1 {
      color: var(--color-text);
      font-size: 1.8rem;

      @include respond(tabLand) {
        // justify-content: space-around;
        font-size: 1.4rem;
      }

    }
  }

  &__section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    // align-items: center;
    width: 100%;
    overflow: hidden;

    &--left {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      // background: gray;
      margin-top: 1rem;
      padding: 0 0 0 0;

      // @include respond(tabLand) {
      //   width: none;
      //   // margin-right: 10rem;
      // }

      @include respond(tabPort) {
        display: none;
        width: 90%;
        align-items: center;
        padding-top: 1rem;
      }

      // Skill Card

      .skillCard {
        height: 5rem;
        width: 5rem;
        background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1.2rem 1rem 0;
        // column-gap: 20;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        @include respond(tabLand) {
          height: 4rem;
          width: 4rem;
        }

        @include respond(tabPort) {
          margin: 0 1.5rem 1.5rem 0;
        }


        &__img {
          height: 3rem;
          width: 3rem;

          @include respond(tabLand) {
            height: 2.5rem;
            width: 2.5rem;
          }
        }

      }
    }

    img {
      height: 25rem;

      @include respond(tabLand) {
        height: 25rem;
      }


    }

    &--right {
      // height: 100%;
      display: flex;
      flex-direction: column;
      margin: 1.5rem 0;
      width: 80%;

      // justify-content: space-between;
      @include respond(tabLand) {
        width: 80%;
      }

      @include respond(tabPort) {
        width: 80%;
        overflow: hidden;
        display: none;
        // max-height: 80vh;
      }

      @include respond(phone) {
        width: 90%;
      }

      &__box {
        display: flex;
        flex-direction: row;


        @include respond(phone) {
          margin-bottom: 0.5rem;
        }

        &--lft {
          align-items: flex-start;
          height: 100%;
          width: 20%;
          // min-height: 25rem;
          display: flex;
          flex-direction: column;
          padding-right: 2rem;

          justify-content: space-between;

          @include respond(phone) {
            // height: 80vh;
            overflow: hidden;
          }

          p {
            // color: #ffbb00;
            font-size: 0.9rem;
            color: var(--color-secondary);
            margin: 0;
            font-family: Comfortaa-Bold;

            @include respond(phone) {
              // height: 80vh;
              font-size: 0.8rem;
            }
          }
        }

        &--rgt {
          height: 100%;
          display: flex;
          flex-direction: column;

          &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 1.5rem;

            @include respond(phone) {
              margin-bottom: 1rem;
            }

            h3 {
              // color: #ff1414;
              color: var(--color-text);
              margin: 0;
              font-size: 1rem;
              text-align: start;

              @include respond(phone) {
                font-size: 0.83rem;
              }

              &:hover {
                color: var(--color-secondary);
                cursor: pointer;
              }
            }

            p {
              margin-top: 0.5rem;
              font-size: 0.6rem;
              font-weight: bold;
              text-transform: uppercase;
              color: var(--color-text-1);

              @include respond(phone) {
                // height: 80vh;
                font-size: 0.5rem;
              }
            }

          }

          // &__top {
          //   display: flex;
          //   flex-direction: row;
          //   align-items: center;

          //   div {
          //     width: 1.6rem;
          //     height: 0.1rem;
          //     background-color: var(--color-secondary);
          //     margin-right: 1rem;
          //   }

          //   h1 {
          //     font-size: 2rem;
          //     color: var(--color-secondary) !important;
          //     font-family: Comfortaa-Bold;
          //     text-align: left;

          //     @include respond(tabLand) {
          //       font-size: 1.6rem;
          //       margin: 0.5rem 0;
          //     }

          //     @include respond(phone) {
          //       font-size: 1.1rem;
          //       margin: 0.3;
          //     }
          //   }

          .brief {
            font-size: 1rem;
            line-height: 2rem;
            color: white;
            font-family: Comfortaa-Light;
            text-align: left;

            @include respond(phone) {
              font-size: 0.8rem;
              margin: 0;
            }

            span {
              color: var(--color-secondary)
            }
          }
        }
      }
    }
  }
}

.vl {
  border-left: 2px dashed var(--color-secondary);
  height: 3rem;
  align-self: center;
}
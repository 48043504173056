@import "./themes/mixins.scss";

.workModal {
  width: 100%;
  // display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 10rem;

  h3 {
    font-size: 2rem;
    margin: 0rem;
    font-family: "Comfortaa-Bold";

    @include respond(tabPort) {
      font-size: 1.5rem;
    }
  }

  // @include respond(phone) {
  //   width: 80% !important;
  //   height: 30rem !important;
  // }

  &__subtitle {
    font-size: 1.1rem;

    @include respond(tabPort) {
      font-size: 0.85rem;
    }
  }

  &__link {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    a {
      &:not(:nth-child(1)) {
        margin-left: 2rem;
      }
      img {
        height: 2rem;
      }
    }
  }

  &__web {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
    a {
      width: 8rem;
      border: 1px solid white;
      border-radius: 0.5rem;
      align-items: center;

      &:hover {
        border: 1px solid var(--color-secondary);
      }
    }

    p {
      color: white;
      margin: 0.4rem;
      font-size: 0.8rem;
      text-align: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    // justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 1rem 15%;
    // height: 10rem;
    background-color: var(--color-secondary);
    color: black;
    margin: 1rem 0;

    @include respond(tabPort) {
      padding: 1rem 5%;
    }

    &--box {
      // background-color: var(--color-quaternary);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 2rem 4rem 2rem 0;

      @include respond(tabPort) {
         margin: 1rem 2rem 1rem 0;
      }

      h4 {
        font-size: 1.1rem;
        margin: 0rem;
        font-family: "Rubik-Bold";

        @include respond(tabPort) {
          font-size: 0.9rem;
        }
      }
      p {
        margin: 0rem;
        padding-top: 1rem;
        font-size: 1.1rem;
        text-align: start;

        @include respond(tabPort) {
          font-size: 0.9rem;
          padding-top: 0.6rem;
        }
      }
    }
  }

  &__screenshot {
    h3 {
      padding-bottom: 1rem;
    }
    &--container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    padding: 1rem 15%;
    align-items: flex-start;

    @include respond(tabPort) {
      padding: 1rem 5%;
    }
    h3 {
      font-size: 1.5rem;
      margin: 0rem;
      font-family: "Lora-Regular";
      text-align: start;

      @include respond(tabPort) {
        font-size: 1.1rem;
        padding-top: 0.6rem;
      }
    }
    p {
      white-space: pre-wrap;
      // font-size: 1.25rem;
      //   margin: 0rem;
      // font-family: 'Lora-Regular';
      text-align: start;

      @include respond(tabPort) {
        font-size: 0.9rem;
      }
    }
    .screenshot {
      height: 20rem;
      margin: 1rem 1rem 1rem 0;
      border-radius: 1rem;


      @include respond(tabLand) {
        height: 15rem;
        border-radius: 0.5rem;
      }

      @include respond(tabPort) {
        height: 10rem;
      }
    }
  }
}

// .RMM__container {
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     pointer-events: none;
//     opacity: 0;
//     visibility: hidden;
//     z-index: 10000;
// }

.RMM__body {
  padding: 6rem 0;
  z-index: 100;
  // overflow: scroll
}

@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      //600px / 16
      @content;
    }
  }

  @if $breakpoint==tabPort {
    @media only screen and (max-width: 56.25em) {
      //900px / 16
      @content;
    }
  }

  @if $breakpoint==tabLand {
    @media only screen and (max-width: 75em) {
      //1200px / 16
      @content;
    }
  }

  @if $breakpoint==bigDesktop {
    @media only screen and (max-width: 90.63em) {
      //1450px / 16
      @content;
    }
  }
}
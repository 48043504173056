@import '../../themes/mixins.scss';

.contact {
  width: 90%;
  // height: 100vh;
  // min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  @include respond(tabLand) {
    width: 95%;
  }

  @include respond(tabPort) {
    // height: 80vh;
  }

  &__top {
    margin-top: 7rem;

    @include respond(tabLand) {
      width: 95%;
    }

    @include respond(tabPort) {
      margin-top: 5rem;
      // margin-bottom: 4rem;
    }


    h1 {
      font-size: 2rem;
      color: var(--color-text);
      font-family: Comfortaa-Bold;
      margin: 1rem 0.5rem;

      @include respond(tabLand) {
        font-size: 1.6rem;
        margin: 0.5rem 0;
      }

      @include respond(tabPort) {
        // flex-direction: row;
        // width: 80%;

      }

      @include respond(phone) {
        font-size: 1.45rem;
        margin: 0.3;
      }

      @include respond(phone) {
        font-size: 1.3rem;
      }
    }

    // p {
    //   color: white;
    //   margin: 0 0.5rem;
    //   font-family: Comfortaa-Light;

    //   @include respond(phone) {
    //     font-size: 0.8rem;
    //     margin: 0;
    //   }
    // }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 70%;

    // justify-content: center;

    @include respond(bigDesktop) {
      width: 85%;
    }

    @include respond(tabLand) {
      width: 90%;

    }

    @include respond(tabPort) {
      align-items: center;
      width: 100%;
    }

    @include respond(phone) {
      width: 90%;
    }

    &--box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 7rem 1rem 0 1rem;

      @include respond(tabPort) {
        flex-direction: column;
        width: 100%;
        margin: 3rem 1rem 0 1rem;
      }

      div {
        display: flex;
        width: 40%;
        height: 3.3rem;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;

        @include respond(tabLand) {
          height: 3rem;
        }

        @include respond(tabPort) {
          flex-direction: row;
          width: 80%;
          padding: 0.2rem 1rem;
        }

        img {
          height: 2rem;

          @include respond(tabLand) {
            height: 1.8rem;
          }

          @include respond(tabPort) {
            height: 2rem;
          }

          @include respond(phone) {
            height: 1.8rem;
          }
        }

        p {
          font-size: 1.2rem;
          color: var(--color-text);
          margin: 0 1rem;
          font-family: Comfortaa-Bold;

          @include respond(tabLand) {
            font-size: 1rem;
            margin: 0 0.7rem;
          }

          @include respond(tabPort) {
            font-size: 1.2rem;
          }

          @include respond(phone) {
            font-size: 0.9rem;
          }
        }
      }

      &--1 {
        margin-left: 1.5rem;
        background-color: #ff518b5e;

        @include respond(tabPort) {
          margin-left: 0;
          margin-bottom: 1.5rem;
        }
      }

      &--2 {
        margin-right: 1.5rem;
        background-color: #71C8EB5e;

        @include respond(tabPort) {
          margin-right: 0;
          padding: 0rem 0rem;
        }
      }
    }
  }


  &__content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
    justify-content: center;
    margin-bottom: 5rem;

    @include respond(bigDesktop) {
      width: 85%;
    }

    @include respond(tabLand) {
      width: 90%;
      justify-content: space-around;
      margin-bottom: 3rem;
    }

    @include respond(phone) {
      width: 80%;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 0rem;
    }

    &--text {
      display: flex;
      flex-direction: row;
      // align-items: center;
      justify-content: center;
      margin: 0;
      // height: 80%;
      width: 30%;

      @include respond(tabLand) {
        width: 40%;
      }

      @include respond(phone) {
        flex-direction: column;
        width: 90%;
      }

      :nth-child(2) {
        display: block;
        width: 10rem;
        margin: auto;
        color: var(--color-secondary);

        @include respond(phone) {
          display: none;
        }
      }

      p {
        color: white;
        margin: 0 0.5rem;
        font-family: Comfortaa-Light;
        font-size: 1.3rem;
        text-align: left;

        @include respond(tabPort) {
          font-size: 1.1rem;
        }

        @include respond(phone) {
          font-size: 0.9rem;
        }

        span {
          color: var(--color-secondary);
        }
      }
    }

    &--form {
      display: flex;
      flex-direction: column;
      width: 100%;

      // background-color: black;
      padding: 3rem 0rem;

      @include respond(tabLand) {
        // width: 40%;
      }

      @include respond(phone) {
        padding: 0rem 0rem;
      }



      &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        @include respond(phone) {
          flex-direction: column;
        }

        input {
          background-color: transparent;
          margin: 1rem auto;
          font-size: 1.1rem;
          font-family: Comfortaa-Regular;
          width: 42%;
          border: none;
          border-bottom: 1px solid var(--color-text);
          padding: 5px 0;
          color: var(--color-text);

          &:last-child {
            width: 92%;

            @include respond(phone) {
              width: 95%;
            }
          }

          @include respond(tabPort) {
            font-size: 1rem;
          }

          @include respond(phone) {
            font-size: 0.9rem;
            width: 95%;
          }

          &:focus {
            outline: none;
            // border-color: var(--color-secondary2);
            // color: var(--color-secondary2);

            // &::placeholder {
            //   /* Chrome, Firefox, Opera, Safari 10.1+ */
            //   color: var(--color-secondary2);
            //   /* Firefox */
            // }
          }
        }
      }

      div {
        //width: 70%;
        display: flex;
        align-self: center;
        align-items: flex-end;
        margin: 1rem 0 1rem 0;


        .btn {
          display: inline-block;
          border: 1px solid #CCC;
          box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
          border: none !important;
          cursor: pointer;
          vertical-align: middle;
          padding: 5px;
          text-align: center;
          display: flex;
          width: 10rem;
          font-size: 1.2rem;
          font-family: Comfortaa-Bold;
          margin-left: auto;
          justify-content: center;
          padding: 0.5rem 0;
          background-color: var(--color-text);
          color: var(--color-bg);

          border-radius: 0.5rem;

          @include respond(tabLand) {
            font-size: 1rem;
          }

          @include respond(tabPort) {
            font-size: 0.9rem;
          }

          @include respond(phone) {
            font-size: 0.8rem;
          }

          &:hover {
            background-color: var(--color-secondary);
            color: var(--color-text-5);
            cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFTSURBVHgBrZS9UsJAFIWPjC1OrCnc0k4stM4M9vAEArUFWFqJFaVxxNrEF4hvEGobtNLONNZEeID1bFbCXxICyZk5k+xN8u3duzcLJMugBQqSoMe0pJ9RgCzzDNJzQqDEFpmWEuKGqJBSwdYqoWBlBQroTSoM6NHftEk3cgPdAVCvhRl6RhkuryO6Qzd3AlaPgcMyU6uR5OoQN8wi3OZ9Nw5Yhe63ZhqYgHnWj3oC6mQVqJbi8mGLs9rQtTrCBhkH8fH9f6C4vdIv9QYQzqtuZDXuXOrM6hfA72Q5libPPIeUn9rcBMlaReMktxrrv+ashu3hGwJHFzysjdqIXTQD+vRDtw8EE+TSYttYwRT+3RNyaREY0G3rBeDyM2k8TQcqDWnnuo+N8n+Aj6/om0h7Me+qNhqxJ8X9TXx7KJgqDa/vHJ4igwRtY35qr1rFe4g5gf4ApeZ0dUcd+qUAAAAASUVORK5CYII="),
              pointer;
          }

          &:active {
            background-color: var(--color-secondary);
          }

          &:focus {
            border: none !important;
            outline: none;

          }
        }
      }
    }
  }

  .footer {
    // position: absolute;
    // bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 1rem;
    background-color: var(--color-bg);
    font-family: Comfortaa-Regular;

    :nth-child(2) {
      font-size: 0.8rem;
      margin: 0.3rem;

      @include respond(phone) {
        font-size: 0.7rem;
      }
    }

    p {
      color: var(--color-text);
      margin: 0.2rem;
      font-size: 0.9rem;

      @include respond(phone) {
        font-size: 0.8rem;
      }

      a {
        color: var(--color-secondary)
      }
    }
  }
}
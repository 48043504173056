@import '../../themes/mixins.scss';



.swiper {
  width: 100%;
  max-height: 23rem !important;
   display: block;
  //  height: unset !important;
   overflow: visible;
  padding-top: 50px;
  padding-bottom: 50px;

  // @include respond(bigDesktop) {
  //   // width: 80% !important;
  //  height: 31.25rem !important;
  // }

  // @include respond(tabLand) {
  //   // width: 60% !important;
  //   height: 31.25rem !important;
  // }

  // @include respond(tabPort) {
  //   // width: 80% !important;
  //  height: 31.25rem !important;
  // }

}

.swiper-slide {
  background-position: center;
  background-size: cover;
  //background-color: gray;
  width: 50rem !important;
  height: 30rem !important;
  //overflow: hidden;
  border-radius: 1rem !important;
  -webkit-border-radius: 1rem !important;
  -moz-border-radius: 1rem !important;
  -ms-border-radius: 1rem !important;
  -o-border-radius: 1rem !important;

  @include respond(bigDesktop) {
    width: 80% !important;
    height: 30rem !important;
  }

  @include respond(tabLand) {
    width: 60% !important;
    height: 25rem !important;
  }

  @include respond(phone) {
    width: 80% !important;
    height: 30rem !important;
  }
}

// .swiper-slide img {
//   display: block;
//   width: 100%;
//   border-radius: 1rem;
// }

.swiper-3d .swiper-slide-shadow-left {
  // background-image: linear-gradient(to left, var(--shadow2), var(--shadow1)) !important;
  background-image: none !important;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  // height: 100vh !important;
}

.swiper-3d .swiper-slide-shadow-right {
  // background-image: linear-gradient(to right, var(--shadow1), var(--shadow2)) !important;
  background-image: none !important;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  // height: 100vh !important;
}



.mySwiper {
  overflow: visible !important;
  position: relative;
}

.swiperSlide {
  display: flex;
  background-color: transparent;
  position: relative;
  // box-shadow: 1px 1px 50px 4px var(--boxShadow);
  align-items: flex-end;

  transition-duration: 9ms;
  transform: translate3d(0 px, 0 px, -383.006 px) rotateX(0deg) rotateY(0deg) scale(1);
  z-index: 0;

  @include respond(tabLand) {
    width: 70% !important;
    // height: 11rem !important;
  }

  @include respond(tabPort) {
    width: 70% !important;
    height: 11rem !important;
  }

  @include respond(phone) {
    width: 90% !important;
    height: 62% !important;
  }


 &__img {
    position: absolute;
    width: 80% !important;
    // height: 28rem;
    top: -3rem !important;
    overflow: hidden;
    background-color: transparent;
    left: 10%;
    border-radius: 1rem;
    background-size: contain;
    box-shadow: 1px 1px 50px 4px var(--boxShadow);

    // @include respond(bigDesktop) {
    // //   height: 30rem !important;
    // // }

    @include respond(tabLand) {
      
      width: 100% !important;
      height: auto !important;
      top: 0rem !important;
      left: 0%;
    }

    @include respond(tabPort) {
      // width: 85% !important;
      position: relative;
      width: 100% !important;
    height: auto !important;
    border-radius: 0.5rem;
    
      // height: 18rem !important;
    }

    @include respond(phone) {
      width: 100% !important;
      height: auto !important;
    }


    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: -0.5rem;
    }
  }
}

.swiperSlide-active {
  display: flex;
  background-color: var(--color-bg);
  position: relative;
  box-shadow: 1px 1px 50px 4px var(--boxShadow);
  align-items: flex-end;

  transition-duration: 9ms;
  transform: translate3d(0 px, 0 px, -383.006 px) rotateX(0deg) rotateY(0deg) scale(1);
  z-index: 0;

  @include respond(bigDesktop) {
    width: 80% !important;
    height: 30rem !important;
  }

  @include respond(tabLand) {
    width: 60% !important;
    height: 25rem !important;
  }

  @include respond(tabPort) {
    width: 70% !important;
  }

  @include respond(tabPort) {
    width: 85% !important;
    height: 20rem !important;
  }


  &__img {
    position: absolute;
    width: 90% !important;
    height: 28rem;
    top: -3rem !important;
    overflow: hidden;
    background-color: cadetblue;
    left: 5%;
    border-radius: 1rem;
    background-size: contain;
    box-shadow: 1px 1px 50px 4px var(--boxShadow);

    // @include respond(bigDesktop) {
    //   height: 30rem !important;
    // }

    @include respond(tabLand) {
      height: 24rem !important;
    }

    @include respond(tabPort) {
      // width: 85% !important;
      height: 18rem !important;
    }


    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2rem;
    align-items: center;

    &--link {
      display: flex;
      align-items: center;

      p {
        &:hover {
          color: var(--color-secondary);
          cursor: pointer;
        }
      }

      i {
        font-size: 1.5rem;
        color: var(--color-text);

        @include respond(tabPort) {
          font-size: 1.2rem;
        }

        &:hover {
          color: var(--color-secondary);
        }
      }
    }

    p {
      font-size: 1.1rem;
      color: var(--color-text);
      // margin: 0 0.5rem;
      font-family: Comfortaa-Bold;



      @include respond(phone) {
        font-size: 0.8rem;
        margin: 0;
      }
    }
  }

}

.work {
  height: 100vh;
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
  // height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  // margin-top: 5rem;

  @include respond(tabPort) {
    height: 80vh;
  }

  &__top {
    margin-top: 5rem;

    @include respond(tabPort) {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }


    h1 {
      font-size: 2rem;
      color: var(--color-text);
      font-family: Comfortaa-Bold;
      margin: 1rem 0.5rem;

      @include respond(tabLand) {
        font-size: 1.6rem;
        margin: 0.5rem 0;
      }

      @include respond(phone) {
        font-size: 1.45rem;
        margin: 0.3;
      }

      @include respond(phone) {
        font-size: 1.3rem;
      }
    }

    // p {
    //   color: white;
    //   margin: 0 0.5rem;
    //   font-family: Comfortaa-Light;

    //   @include respond(phone) {
    //     font-size: 0.8rem;
    //     margin: 0;
    //   }
    // }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 0.5rem;
    // color: #ffffffea

    @include respond(tabPort) {
      margin-bottom: 1rem;
      margin-top: 0rem;
    }

    p {
      font-size: 1.3rem;
      color: var(--color-text);
      font-family: Comfortaa-Bold;
      margin: 0rem 1.5rem;

      @include respond(tabPort) {
        font-size: 1.2rem;
      }

      @include respond(phone) {
        font-size: 1.1rem;
        // margin: 0.3;
      }
    }

    i {
      font-size: 1.5rem;
      color: var(--color-text);
    }
  }
}

a {
  text-decoration: none;
}

.rec-dot_active {
  background-color: var(--color-secondary) !important;
  box-shadow: 0 0 1px 3px var(--color-secondary) !important;

  @include respond(tabPort) {
    height: 6px !important;
    width: 6px !important;
  }

  @include respond(phone) {
    height: 5px !important;
    width: 5px !important;
  }
}

.rec-dot {
  box-shadow: 0 0 1px 2px rgba(255, 255, 255, 0.5) !important;

  @include respond(tabPort) {
    height: 6px !important;
    width: 6px !important;
  }

  @include respond(phone) {
    height: 5px !important;
    width: 5px !important;
  }
}

.rec-arrow {
  background-color: var(--color-secondary) !important;
  color: var(--color-primary) !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  line-height: 40px !important;
  font-size: 1.2em !important;
}

.techCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  margin-right: 1rem;

  @include respond(tabPort) {
    height: 2rem;
    width: 2rem;
  }

  @include respond(phone) {
    height: 2rem;
    width: 2rem;
  }

  &__img {
    height: 1.5rem;
    width: 1.5rem;

    @include respond(tabPort) {
      height: 1.3rem;
      width: 1.3rem;
    }

    @include respond(phone) {
      height: 1.3rem;
      width: 1.3rem;
    }
  }
}
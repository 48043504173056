@import './themes/mixins.scss';

// DESKTOP
.App-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  // background: transparent;
  height: 6rem;
  font-size: calc(10px + 2vmin);
  padding: 0rem 0;
  z-index: 2;

  @include respond(tabPort) {
    display: none;
  }

  &--container {
    display: flex;
    width: 85%;
    height: 6rem;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    align-items: center;
    // color: #03060c;
    // background-color: #04070e;

    .resume {
      display: flex;
      // width: 10rem;
      flex-direction: row;
      align-items: center;
      margin-left: 3rem;
      color: var(--color-text);
      font-size: 1.5rem;
      // marginBottom: "0.5rem";
      height: 2rem;
      border-radius: 0.5rem;
      padding: 0 1rem;
      // backgroundColor: var(--color-text-3);
      background-color: var(--color-text-3);

      @include respond(tabLand) {
        // width: 5.5rem;
        height: 2rem;
      }

      &:hover {
        background-color: var(--color-secondary);
        color: var(--color-text-5);
      }

      p {
          font-size: 0.95rem;
          @include respond(tabLand) {
            font-size: 0.85rem
          }
      }

      i {
        margin-left: 1rem;
        font-size: 0.95rem;

        @include respond(tabLand) {
          margin-left: 0.7rem;
          font-size: 0.85rem;
        }

        @include respond(phone) {
          font-size: 1rem;
        }
      }
    }

    .Logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      justify-content: center;

      p {
        font-family: NicoMoji-Regular;
        font-size: 1.8rem;
        color: var(--color-text);
      }
    }

    .App-logo-header {
      margin: auto 0.1rem;
      padding-bottom: 0.3rem;
      height: 2.2rem;

      &:hover {
        transform: rotate(360deg);
      }
    }

    // @media (prefers-reduced-motion: no-preference) {
    //   .App-logo-header {
    //     &:hover {
    //     animation: App-logo-header-spin infinite 5s linear;
    //     -webkit-animation: App-logo-header-spin infinite 5s linear;
    //     }
    //   }
    // }

    &__main-nav {
      display: flex;
      flex-direction: 'row';
      align-items: center;
      justify-content: center;

      a {
        display: flex;
        font-family: Comfortaa-Regular;
        font-size: 1rem;
        text-decoration: none;
        text-transform: capitalize;
        margin: 0 0 0 2rem;
        color: var(--color-text);
        font-weight: normal;

        p {
          
          position: relative;
          display: inline-block;
          margin: 0;
          text-align: center;
          line-height: 1.2em;
        }

        p:after,
        p:before {
          display: block;
          content: '';
          border-bottom: solid 3px var(--color-secondary);
          transform: scaleX(0);
          transition: transform 250ms ease-in-out;
        }

        p:before {
          position: absolute;
          bottom: 1.2em;
          left: 0;
          // width: 100%;
        }

        p:hover:after,
        p:hover:before {
          transform: scaleX(1);
        }
      }
    }
  }
}


// Mobile

.mobile-header {
  width: 100%;
  justify-content: center;
  background-color: var(--color-bg);
  box-shadow: 1px 1px 4px 0 var(--boxShadow);
  position: fixed;
  z-index: 101;
  display: none;

  @include respond(tabPort) {
    display: block;
    align-items: center;
  }
}

.header {
  // background-color: transparent;
  margin: auto;
  width: 90%;
  height: 3.1rem;
  align-items: center;
  justify-content: space-between;

  @include respond(tabPort) {
    display: block;
  }

  .Logo1 {
    display: block;
    float: left;
    // display: flex;
    flex-direction: row;
    // align-self: center;
    align-items: center;
    margin-top: 0.8rem;

    cursor: pointer;
    text-decoration: none;
    justify-content: center;

    p {
      font-family: NicoMoji-Regular;
      font-size: 1.8rem;
      color: var(--color-text);
      margin: 0;

      @include respond(tabPort) {
        font-size: 1.2rem;
      }
    }

    .App-logo-header {
      margin: auto 0.1rem;
      padding-bottom: 0.3rem;
      height: 2.2rem;

      @include respond(tabPort) {
        height: 1.7rem;

      }
    }

    // @media (prefers-reduced-motion: no-preference) {
    //   .App-logo-header {
    //     animation: App-logo-header-spin infinite 5s linear;
    //     -webkit-animation: App-logo-header-spin infinite 5s linear;
    //   }
    // }
  }
}

.header ul {
  // width: 110%;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: var(--color-bg);
}

.header li a {
  display: block;
  padding: 20px 20px;
  // border-right: 1px solid #f4f4f4;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: none;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  width: 100vw;
  position: fixed;
  top: 3rem;
  left: 0;
  clear: both;
  height: 0;
  //max-height: 0;
  transition: height .2s ease-out;
  -webkit-transition: height .2s ease-out;
  -moz-transition: height .2s ease-out;
  -ms-transition: height .2s ease-out;
  -o-transition: height .2s ease-out;

  p {
    color: var(--color-text);
    font-size: 1.4rem;
    font-family: Comfortaa-Regular;
    margin: 0.5rem 0;

    @include respond(tabPort) {
      font-size: 1.2rem;
    }
  }
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 1.5rem 0;
  position: relative;
  user-select: none;

  @include respond(tabPort) {
    // padding: 1.5rem 1.5rem;
  }
}

.header .menu-icon .navicon {
  background: var(--color-text);
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 1.7rem;

  @include respond(tabPort) {
    height: 1px;
    width: 1.5rem;
  }
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: var(--color-text);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 8px;

  @include respond(tabPort) {
    top: 6px;
  }

}

.header .menu-icon .navicon:after {
  top: -8px;

  @include respond(tabPort) {
    top: -6px;
  }
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked~.menu {
  padding-top: 1rem;
  height: 100vh;
}

.header .menu-btn:checked~.menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
  top: 0;
}

.mobile-social {
  // flex: 1;
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-bg);
  // height: 100%;
  width: 100vw;
  bottom: 1rem;
  align-items: center;
  z-index: 100;

  @include respond(tabPort) {
    display: flex;
    bottom: 0rem;
  }

  &--content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto 0;
    // @include respond(tabLand) {
    //   display: flex;
    //   flex-direction: column;
    //   margin: auto;
    // }

    a {
      display: flex;
      margin: 1.5rem 1.2rem;
      width: 2rem;
      height: 2rem;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: var(--color-text);
      flex-wrap: wrap;

      @include respond(tabLand) {
        width: 1.5rem;
        height: 1.5rem;
      }

      &:hover {
        background-color: var(--color-secondary);
      }


      i {
        display: flex;
        width: 2rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        color: var(--color-bg);
        font-size: 1rem;

        @include respond(tabLand) {
          width: 1.5rem;
          height: 1.5rem;
        }

        @include respond(phone) {
          font-size: 1rem;
        }
      }
    }
  }
}
.mobile-actions {
  display: flex;
  position: absolute;
  right: 5rem;
  top: 0.5rem;
  align-items: center;

@include respond(phone) {
  right: 4rem;
}
  
 .resume {
   display: flex;
   min-width: 5rem;
   flex-direction: row;
   align-items: center;
   margin-left: 2rem;
    margin-right: 1rem;
   color: var(--color-text);
   font-size: 1.5rem;
   // marginBottom: "0.5rem";
   height: 2rem;
   border-radius: 0.5rem;
   padding: 0 1rem;
   // backgroundColor: "var(--color-secondary)";
   background-color: var(--color-text-3);

   @include respond(tabLand) {
     // width: 5.5rem;
     height: 1.5rem;
   }

   @include respond(tabPort) {
     // width: 5.5rem;
     height: 1.7rem;
   }

   @include respond(phone) {
     // width: 5.5rem;
     height: 1.5rem;
     font-size: 1rem;
   }
   &:hover {
     background-color: var(--color-secondary);
     color: var(--color-text-5);
   }

   p {
     font-size: 0.95rem;

     @include respond(tabLand) {
       font-size: 0.85rem
     }
     
   }

   i {
     margin-left: 1rem;
     font-size: 0.95rem;

     @include respond(tabLand) {
       margin-left: 0.7rem;
       font-size: 0.85rem;
     }

     @include respond(phone) {
       font-size: 1rem;
     }
   }
 }

.mobile-toggle {
  display: flex;
  background-color: var(--color-mode-bg);
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  justify-content: center;
  align-items: center;

  @include respond(phone) {
    right: 18%;
  }
}
}
@keyframes App-logo-header-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
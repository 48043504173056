//Color

:root {
  --mode: dark;
  --color-primary: #04070e;
  --color-primary-light: #05da3e;
  --color-primary-dark: #03060c;
  --color-primary-dark2: #020305;

  --color-secondary: #FFD700;
  --color-secondary2: #c2a500;
  --color-secondary3: #ffc400;
  --color-secondary-trans: #ffd9007c;

  --color-tertiary: #7721ba;

  --color-quaternary: #c11d6f;

  --home-bg-opacity: 0.9;

  --color-mode-icon: #FFD700;
  --color-mode-bg: #ffffff21;

  --color-light: #ffffff;
  --color-light-trans: #ffffff21;
  --color-bg: #04070e;
  // --color-bg: #fcfcfc;
  --color-bg-2: #050c07;
  --color-card: #f4f2f2;
  --color-border: #c0c8cb;
  --color-btn-hover: #a8a8a8;

  --color-grey-light-1: #fafafa;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #cccccc;
  --color-grey-light-5: #a8a8a8;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;

  --color-dark-1: #000000;
  --color-dark-2: #0e0e0e;
  --color-dark-3: #111111;
  --color-dark-4: #1a1a1a;

  --color-text: #FFFFFF;
  --color-text-1: #777777;
  --color-text-2: #242424;
  --color-text-3: #242424;
  --color-text-4: #e6e1e1;
  --color-text-5: #242424;
  // --color-text-4: #b8b8b8;

  // --color-text-1: #a3a3a3;
  // --color-text-2: #cccccc;

  --color-plan-free: #5d6f7d;
  --color-plan-basic: #5dcd72;
  --color-plan-premium: #e63950;

  --color-danger-1: #ff0000;
  --color-danger-2: #ff3d3d;

  --color-verify: #3897f0;
  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-grey-light-2);

  --scrollbarBG: #e4ffeb;
  --color-white: #ffffff;
  --color-gray: #777777;
  // --scrollbarBG: #020503;
  --thumbBG: #05da3e;
  --boxShadow: #ffffff0a;
  --shadow1: rgba(0, 0, 0, .6);
  --shadow2: rgba(0, 0, 0, .7);
  ---test: #ffffff6e;
}

// FONT SIZES
// $default-font-size: 1.6rem;
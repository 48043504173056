@import './themes/mixins.scss';

.App {
  text-align: center;
  background-color: var(--color-bg);
  align-items: center;
  width: 100%;
}

.social {
  // flex: 1;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100%;
  bottom: 1rem;
  left: 2rem;
  align-items: center;
  z-index: 100;

  @include respond(tabLand) {
    left: 1rem;
  }

  @include respond(tabPort) {
    display:  none;
  }

  &--content {
    display: flex;
    flex-direction: column;
    margin: auto 0;

    // @include respond(tabLand) {
    //   display: flex;
    //   flex-direction: column;
    //   margin: auto;
    // }

    a {
      display: flex;
      margin: 1.5rem 0;
      width: 2rem;
      height: 2rem;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: var(--color-text);

      @include respond(tabLand) {
        width: 1.5rem;
        height: 1.5rem;
      }

      &:hover {
        background-color: var(--color-secondary);
      }


      i {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        justify-content: center;
        align-items: center;
        color: var(--color-bg);
        font-size: 1rem;

        @include respond(tabLand) {
          width: 1.5rem;
          height: 1.5rem;
        }

        @include respond(phone) {
          font-size: 1rem;
        }
      }
    }

    // .resume {
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   margin-left: 3rem;
    //   color: var(--color-text);
    //   font-size: 1.5rem;
    //   // marginBottom: "0.5rem";
    //   height: 2rem;
    //   border-radius: 0.5rem;
    //   padding: 0 1rem;
    //   background-color: var(--color-secondary);
    //   // background-color: var(--color-text-3);

    //   @include respond(tabLand) {
    //     // width: 1.5rem;
    //     height: 1.5rem;
    //   }

    //   &:hover {
    //     background-color: var(--color-secondary);
    //     color: black;
    //   }


    //   i {
    //     display: flex;
    //     width: 2.5rem;
    //     height: 2.5rem;
    //     justify-content: center;
    //     align-items: center;
    //     color: var(--color-bg);
    //     font-size: 1rem;

    //     @include respond(tabLand) {
    //       width: 1.5rem;
    //       height: 1.5rem;
    //     }

    //     @include respond(phone) {
    //       font-size: 1rem;
    //     }
    //   }
    // }
  }
}

.loader {
  position: fixed;
  width: 100vw;
  // height: 100vh;
  background: rgba(0, 0, 0, 0.979);
  // filter: blur(8px);
  // -webkit-filter: blur(8px);
  z-index: 102;
  display: flex;
  justify-content: center;

  &__Logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    justify-content: center;

    p {
      font-family: NicoMoji-Regular;
      font-size: 2.8rem;
      color: white;
      margin: 0 -0.5rem;
    }

    &--main-logo {
      height: 4rem;
    }

    &--App-logo {
      position: absolute;
      margin: auto;
      padding-bottom: 8rem;
      height: 1.2rem;

      @media (prefers-reduced-motion: no-preference) {
        animation: App-logo-spin infinite 3s linear;
        -webkit-animation: App-logo-spin infinite 3s linear;
      }
    }

  }
}

.scrollText {
  font-size: 0.9rem;
  color: white;
  position: absolute;
  bottom: 12rem;
  right: 0;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);

  p {
    margin: 0;
  }
}

.Logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  //transition: transform 0.6s;
  transform-style: preserve-3d;
  transition: transform .7s ease-in-out;
  -webkit-transition: transform .7s ease-in-out;
  -moz-transition: transform .7s ease-in-out;
  -ms-transition: transform .7s ease-in-out;
  -o-transition: transform .7s ease-in-out;


  &:hover {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
    -webkit-animation: App-logo-spin infinite 5s linear;
  }
}

.App-logo-header {
  height: 40vmin;
  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
    .App-logo-header:hover {
      animation: App-logo-header-spin infinite 5s linear;
      -webkit-animation: App-logo-header-spin infinite 5s linear;
    }
  }
}



.active>span {
  background-color: var(--color-secondary) !important;
}

#fp-nav ul li,
.fp-slidesNav ul li {
  text-transform: capitalize !important;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

.scroll-down {
  // display: flex;
  // flex-direction: row;
  position: fixed;
  bottom: 10rem;
  right: -0.5em;
  // left: 0;
  z-index: 0;
  color: var(--color-secondary);
  font-family: Comfortaa-Light;
  font-size: 0.8rem;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
}

.scroll-down p {
  margin-right: -3rem;
  display: none;

  // margin: 0;
  // padding: 0;
  @include respond(tabPort) {
    display: block;
    font-size: 0.7rem;
    margin-right: -2rem;
  }

  @include respond(phone) {
    font-size: 0.7rem;
    margin-right: -3rem;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes App-logo-spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes App-logo-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


// Header 
@keyframes App-logo-header-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@-moz-keyframes App-logo-header-spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes App-logo-header-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes App-logo-header-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


#fp-nav.fp-right {
  right: 2% !important;

  @include respond(phone) {
    right: 0% !important;
  }
}

.tippy-popper {
  z-index: 99999 !important;
}